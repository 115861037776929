
import { AuthAction, withAuthUser } from 'next-firebase-auth';
import React from 'react'
import BackArrow from '../BackArrow';
import IALink from '../IALink';

// export const PREV_QUERY_PARAM = "p"

export interface BackNavBarProps { defaultBack?: string }

function BackNavBar({ defaultBack }: BackNavBarProps) {
  return (
    <div className='flex-none navbar flex flex-row w-full bg-neutral text-neutral-content px-3 md:px-6 z-10'>
      <BackArrow defaultBack={defaultBack} />
    </div>
  )
}

function LoadingNavBar() {
  return <div className='w-full navbar'></div>
}

// export default BackNavBar

export default withAuthUser<BackNavBarProps>({
  whenAuthed: AuthAction.RENDER,
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.RENDER,
  LoaderComponent: LoadingNavBar,
})(BackNavBar);

/**
 * This function returns an element that will redirect to a page with a previous page parameter. If the previous page is a back 
 * button page then it when the back button is pressed the user will go back to the defined page
 */
export function backButtonLink({ children, prevPage, loc }: { children: JSX.Element, prevPage: string | null, loc: string }): JSX.Element {
  return <IALink href={loc} passHref>{children}</IALink>
  // return <IALink href={`${loc}${prevPage ?? `?${PREV_QUERY_PARAM}=${prevPage}`}`} passHref>{children}</IALink>
}