import { AuthAction, useAuthUser, withAuthUser } from "next-firebase-auth";
import { useRouter } from "next/router";
import React from "react";
import { IOS_PWA_APP, ANDROID_REFERRER } from "utils/consts";
import useRouterBack from "utils/useBackButton";
import BackArrowSvg from "./svgs/BackArrowSvg";

export interface BackArrowProps { defaultBack?: string }

function BackArrow({ defaultBack }: BackArrowProps) {
  const router = useRouter();
  const path = router.pathname
  const authUser = useAuthUser();
  const isBackable = useRouterBack();

  return (
    <div onClick={() => {

      if (defaultBack) {
        router.push(buildDefaultBack(defaultBack, path));
        return
      }

      if (isBackable) {
        router.back()
        return
      }


      // const isMob = isMobileApp();
      const isMob = window.navigator.userAgent === IOS_PWA_APP || document.referrer.includes(ANDROID_REFERRER)

      const isAuthed = !!authUser.id

      if (isAuthed && isMob) {
        router.push("/mobApp");
      } else if (isAuthed) {
        router.push("/app");
      } else if (isMob) {
        router.push("/mobileLanding");
      } else {
        router.push("/");
      }
      return
    }}>
      <BackArrowSvg />
    </div>
  );
}


export default withAuthUser<BackArrowProps>({
  whenAuthed: AuthAction.RENDER,
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.RENDER,
})(BackArrow);

function buildDefaultBack(defaultBack: string, currentPath: string): string {

  if (currentPath.startsWith("/app/")) {
    return defaultBack.replace("/mobApp/", "/app/")
  } else if (currentPath.startsWith("/mobApp/")) {
    return defaultBack.replace("/app/", "/mobApp/")
  }

  return defaultBack

}
