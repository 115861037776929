import React from "react";
import Spinner from "react-spinner-material";

// TODO: make the loader in the center of the page
function IAPageLoader() {
  return <div className="w-screen h-screen flex flex-col ">
    <div className="justify-center w-full h-full flex flex-row">
      <div className="justify-center"><Spinner /></div>
    </div>
  </div>;
}

export default IAPageLoader;
