import { AuthAction, withAuthUser } from "next-firebase-auth";
import React from "react";
import Image from "next/image";

import FirebaseAuth from "../components/FirebaseAuth";
import BackButtonPage from "components/common/BackButtonPage";
import IAPageLoader from "components/common/IAPageLoader";

function Auth() {

  const bg = <Image
    alt="Climber"
    src="/img/login-bg.jpg"
    layout="fill"
    objectFit="cover"
    className="z-0"
    priority={true}
  />

  return (
    <BackButtonPage bg={bg} >
      <FirebaseAuth />
    </BackButtonPage>
  )
}

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.RENDER,
  LoaderComponent: IAPageLoader
})(Auth);
