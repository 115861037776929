import useIsMobileApp from "./useIsMobileApp";

export default function useRouterBack(): boolean {
  const isMobile = useIsMobileApp();

  // If its the mobile app then we should be limited to indoorascents.com thus we can just go back
  if (isMobile) {
    return true;

    // TODO: This still doesn't work right. Maybe we should use a library?
  } else if (
    document.referrer.startsWith("https://www.indoorascents.com") ||
    document.referrer.startsWith("http://localhost")
  ) {
    return true;
  }

  return false;
}
