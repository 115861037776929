import { useRouter } from "next/router";
import { ANDROID_REFERRER, IOS_PWA_APP } from "./consts";

export default function useIsMobileApp(): boolean {
  const router = useRouter();
  const path = router.pathname;

  const host = window.location.hostname;

  const localMob = host === "localhost" && path.startsWith("/mobApp/");

  if (
    document.referrer.includes(ANDROID_REFERRER) ||
    window?.navigator.userAgent === IOS_PWA_APP ||
    localMob
  ) {
    return true;
  }

  // If we dont' know then just send them to the webapp
  return false;
}
