import React from 'react';

import BackNavBar from './NavBars/BackNavBar';

export interface BackButtonPageProps { children: JSX.Element, bg?: JSX.Element, defaultBack?: string }


function BackButtonPage({ children, bg, defaultBack }: BackButtonPageProps) {

  const bgClass = bg ? 'w-full flex-grow flex flex-col justify-center z-10' : 'w-full flex-grow flex flex-col justify-center z-10 bg-slate-100'

  return (
    <div className='w-screen h-screen relative bg-slate-100'>
      <div className='w-full h-full flex flex-col z-10'>
        <BackNavBar defaultBack={defaultBack} />
        <div className={bgClass}>
          <div className='flex-none w-full flex flex-row justify-center'>
            <div className='w-full sm:w-2/3 max-w-lg'>{children}</div>
          </div>
        </div>
      </div>
      {
        bg ?
          <div className='w-full h-full z-0 opacity-60 inline'>
            {bg}
          </div> : <></>
      }
    </div>
  )
}

export default BackButtonPage